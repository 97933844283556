.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px 20px;
}

.top {
  display: flex;
  max-width: 1300px;
  width: 100%;
  user-select: none;
  justify-content: center;
}

.videoContainer {
  position: relative;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video {
  max-width: 100%;
  max-height: 100%;
}

.poster {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  opacity: 0;
}

.posterActive {
  opacity: 1;
}

.controls {
  align-self: flex-end;
  margin-left: 20px;
  width: 250px;
  pointer-events: none;
  opacity: 0.6;
}

.controlsActive {
  pointer-events: all;
  opacity: 1;
}

.control {
  padding: 1.5rem 2rem;
  &:not(:first-child) {
    margin-top: 10px;
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.timeline {
  background: #fff;
  height: 15px;
  width: 100%;
  position: relative;
  display: flex;
}

.timelineHandle {
  background: #999;
  height: 15px;
  cursor: row-resize;
}

.timelineProgress {
  background: #333;
  height: 100%;
  width: 10%;
}

.timelineHover {
  position: absolute;
  top: -20px;
  background: #fff;
  font-size: 1.2rem;
  padding: 2px 10px;
  border-radius: 3px;
  transform: translateX(-50%);
}

.videoContainer {
  position: relative;
}

.capture {
  padding: 5px 10px;
  width: 100%;
  background: #ddd;
  margin-top: 20px;
  position: relative;
}

.captureBox {
  background: rgba(61, 133, 198, 0.3);
  position: absolute;
  left: 0;
  top: 0;
  border: 5px solid #3d85c6;
  border-left-width: 10px;
  border-right-width: 10px;
  border-radius: 3px 10px 10px 3px/3px 25px 25px 3px;
  height: 100%;
  z-index: 3;
  color: #3d85c6;
  cursor: row-resize;
}

.captureProgress {
  position: absolute;
  background: #3d85c6;
  opacity: 0.7;
  left: 0;
  height: 100%;
  pointer-events: none;
}

.captureLeft,
.captureRight {
  position: absolute;
  left: -25px;
  height: 100%;
  width: 40px;
  z-index: 2;
  cursor: ew-resize;
}

.captureRight {
  left: auto;
  right: -25px;
}

.captureRight:after,
.captureRight:before,
.captureLeft:after,
.captureLeft:before {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
}
.captureRight:before,
.captureLeft:before {
  left: 0;
  border-right: 10px solid #3d85c6;
}

.captureRight:after,
.captureLeft:after {
  right: 0;
  border-left: 10px solid #3d85c6;
}

.frames {
  height: 70px;
  width: 100%;
  pointer-events: none;
  overflow-y: hidden;
}

@media only screen and (max-width: 600px) {
  .top {
    flex-direction: column;
  }
  .controls {
    justify-self: space-between;
    display: flex;
    align-self: flex-start;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0 0 0;
    .control {
      margin-top: 0;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
