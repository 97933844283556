.hero {
  padding: 3rem;
  margin: 0 0.5rem;
  background: #1e1e1e;
  border-radius: 5px;
}

.form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  padding: 1rem;
  outline: none;
  background: #000;
  border: 1px solid #464646;
  font-size: 1.8rem;
  color: #fff;
  border-radius: 5px 0 0 5px;
  width: 100%;
  &:placeholder {
    color: lighten($color: #000000, $amount: 80%);
  }
}

.formButton {
  border-radius: 0 5px 5px 0;
  width: fit-content;
}
