* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Avenir";
}

html,
body {
  height: 100%;
  background: #111;
  font-size: 62.5%;
}

.content {
  max-width: 1440px;
  margin: 0.5rem auto;
}
