.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #1e1e1e;
  padding: 1.2rem;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #d4d4d4;
  text-align: center;
}
