.btn {
  font-size: 1.8rem;
  color: #fff;
  background: #333;
  outline: none;
  border: none;
  padding: 1rem 2rem;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  transition: background 200ms ease-in-out;
  &:hover {
    background: #222;
  }
}

.primary {
  @extend .btn;
  background: #3d85c6;
  &:hover {
    background: darken($color: #3d85c6, $amount: 10%);
  }
}
