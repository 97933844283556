.form {
  display: flex;
  flex-direction: column;
}

.label {
  color: #fff;
  font-size: 1.8rem;
  padding: 1rem;
  width: 100px;
  white-space: nowrap;
}

.checkboxLabel {
  border-radius: 0;
}

.checkboxGroup {
  border-radius: 5px;
  display: flex;
  width: 100%;
  overflow: hidden;
}

.checkboxGroup input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.checkboxGroup input[type="radio"]:checked + .checkboxLabel {
  background: #3d85c6;
}

.formItem {
  display: flex;
  align-items: center;
  margin-bottom: 1.8rem;
}

.input {
  width: 100%;
  padding: 1rem;
  border: none;
  outline: none;
  font-size: 1.8rem;
  color: #fff;
  border-radius: 5px 0 0 5px;
  background: #1e1e1e;
  &:placeholder {
    color: lighten($color: #000000, $amount: 80%);
  }
}
